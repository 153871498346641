import React from 'react';
import SEO from '@codedrop/seo/esm/seo';
import Header from '@codedrop/header/esm/header';
import Layout from '@codedrop/layout/esm/layout';
import Block from '@codedrop/block/esm/block';
import BlockGraphic from '@codedrop/block-graphic/esm/block-graphic';
import Footer from '@codedrop/footer/esm/footer';
import setHTML from '@codedrop/utils/esm/utils';
import data from '../data/skpr.json';
import security from '../data/security.json';
import SkprLogo from '-!svg-react-loader!../images/skpr-logo-black.svg';
import IconLayer from '-!svg-react-loader!../images/icon-layer.svg';
import IconFeature from '-!svg-react-loader!../images/icon-feature.svg';
import SimpleCta from "@codedrop/cta/esm/simple-cta";

const { seo, header, footer, ctaForm } = data;
const {
  site,
  pageTitle,
  intro,
  cta,
  dataCentres,
  securityPolicies,
  securityPractices,
  stack
} = security;

cta.modalContent = ctaForm;

const SecurityPage = () => {
  return (
    <div>
      <SEO title={site.title} seo={seo} />
      <Header header={header} />
      <main>

        <Layout className="background--firework section--top-l security__intro">
          <Block
            title={{ tag: 'h1', text: pageTitle }}
            subtitle={{ tag: 'h2', ...intro.title }}
            modifier="hero"
          >
            <p className="text--l">{setHTML(intro.content)}</p>
          </Block>
        </Layout>

        {dataCentres && (
            <Layout className='background--firework-right background--firework-large'>
                <BlockGraphic
                    title={dataCentres.title}
                    subtitle={dataCentres.subtitle}
                    graphic="image-aws-data-centres.svg"
                >
                    <div className="text--s">{setHTML(dataCentres.content)}</div>
                </BlockGraphic>
            </Layout>
        )}

        {securityPolicies && (
          <BlockGraphic
            title={securityPolicies.title}
            graphic="image-aus-gov-security-policies.svg"
            reverse
          >
            <div className="text--s">{setHTML(securityPolicies.content)}</div>
          </BlockGraphic>
        )}

        {securityPractices && (
            <BlockGraphic
                title={securityPractices.title}
                subtitle={securityPractices.subtitle}
                graphic="image-transparent-security.svg"
            >
              <div className="text--s">{setHTML(securityPractices.content)}</div>
            </BlockGraphic>
        )}

        {stack && (
          <Layout className="background--firework security__table">
              <Block
                  subtitle={{ tag: 'h2', ...stack.title }}
                  modifier="hero"
              >
                  <ol className="table-style" title="Layers of the stack explained">
                      <li className="table-style__logo" aria-hidden>
                          <SkprLogo />
                      </li>
                      <li className="table-style__section table-style__section--header" aria-hidden>
                          <span><IconLayer /> Layer</span>
                          <span><IconFeature />Feature</span>
                          <span className="table-style__highlight icon icon--info">Description</span>
                      </li>

                      {/* 1. Layer */}
                      <li className="table-style__section">
                          <h3 id='stack1' className="table-style__title">
                              <span className="visually-hidden">First layer:</span>
                              <span aria-hidden>1</span><span>Edge</span>
                          </h3>
                          <dl aria-labelledby="stack1" className="table-style__list table-style__fence">
                              <dt><span class="bottom-arrow">Firewall</span></dt>
                              <dd className="table-style__highlight table-style__fence">A Web Application Firewall or WAF protects apps and APIs against bots and exploits that
                                  consume resources, skew metrics, or cause downtime.
                              </dd>

                              <dt><span class="bottom-arrow">Certificates</span></dt>
                              <dd className="table-style__highlight table-style__fence">Certificates are managed using AWS Certificate Manager.</dd>
                          </dl>
                      </li>

                      {/* 2. Layer */}
                      <li className="table-style__section">
                          <h3 id='stack2'  className="table-style__title">
                              <span className="visually-hidden">Second layer:</span>
                              <span aria-hidden>2</span><span>API</span>
                          </h3>
                          <dl aria-labelledby="stack2" className="table-style__list table-style__fence">
                              <dt><span class="bottom-arrow">Role-Based Access Control</span></dt>
                              <dd className="table-style__highlight table-style__fence">All access is managed centrally using AWS IAM for Role Based Access Control.
                              </dd>
                          </dl>
                      </li>

                      {/* 3. Layer */}
                      <li className="table-style__section">
                          <h3 id='stack3' className="table-style__title">
                              <span className="visually-hidden">Third layer:</span>
                              <span aria-hidden>3</span><span>Compute</span>
                          </h3>
                          <dl aria-labelledby="stack3" className="table-style__list table-style__fence">
                              <dt><span className="bottom-arrow">Vulnerability Management</span></dt>
                              <dd className="table-style__highlight table-style__fence">AWS Inspector provides continuous, automated security vulnerability assessments for infrastructure and applications.
                              </dd>
                              <dt><span className="bottom-arrow">Intrusion Detection</span></dt>
                              <dd className="table-style__highlight table-style__fence">Falco highlights unusual activity, alerting you to potential security threats and flagging compliance violations, helping to provide thorough runtime security.
                              </dd>
                              <dt><span className="bottom-arrow">Read Only Filesystem</span></dt>
                              <dd className="table-style__highlight table-style__fence">All applications are deployed with read only filesystems to ensure that packaged application code cannot be modified by a malicious actor.
                              </dd>
                              <dt><span className="bottom-arrow">Asset Scanning</span></dt>
                              <dd className="table-style__highlight table-style__fence">ClamAV is an operating system agnostic, open-source, anti-virus that enables applications to scan file uploads for malicious content.
                              </dd>
                              <dt><span className="bottom-arrow">Managed Services</span></dt>
                              <dd className="table-style__highlight table-style__fence">AWS Managed Services are leveraged at many layers of the stack e.g. CDN, Database, Filesystem etc. These services either automatically patch and/or manage their own patching/update workflows during a maintenance window.
                              </dd>
                          </dl>
                      </li>

                      {/* 4. Layer */}
                      <li className="table-style__section">
                          <h3 id='stack4' className="table-style__title">
                              <span className="visually-hidden">Fourth layer:</span>
                              <span aria-hidden>4</span><span>Account</span>
                          </h3>
                          <dl aria-labelledby="stack4" className="table-style__list table-style__fence">
                              <dt><span className="bottom-arrow">Threat Detection</span></dt>
                              <dd className="table-style__highlight table-style__fence">GuardDuty provides a threat detection service. It’s continuously monitoring and checking AWS accounts and workloads for external threats.
                              </dd>
                          </dl>
                      </li>

                      {/* 5. Layer */}
                      <li className="table-style__section">
                          <h3 id='stack5' className="table-style__title">
                              <span className="visually-hidden">Fifth layer:</span>
                              <span aria-hidden>5</span><span>Process</span>
                          </h3>
                          <dl aria-labelledby="stack5" className="table-style__list table-style__fence">
                              <dt><span className="bottom-arrow">Frequently Patched</span></dt>
                              <dd className="table-style__highlight table-style__fence">All infrastructure and application runtimes are frequently patched to ensure your applications are as secure as possible.
                              </dd>
                              <dt><span className="bottom-arrow">Fresh Base Images</span></dt>
                              <dd className="table-style__highlight table-style__fence">Base container images are rebuilt nightly with the latest features and security patches included.
                              </dd>
                              <dt><span className="bottom-arrow">Documentation</span></dt>
                              <dd className="table-style__highlight table-style__fence">Skpr maintains a publically available, up-to-date set of documentation that outlines our product and procedures.
                              </dd>
                          </dl>
                      </li>

                  </ol>
            </Block>
          </Layout>
        )}

        <SimpleCta cta={cta} />
      </main>
      <Footer footer={footer} />
    </div>
  );
};

export default SecurityPage;
